<template>
  <sdPageHeader title="Plans"> </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-form :hideRequiredMark="false" ref="formRef" :model="form" :rules="formRules">
        <a-row :gutter="25">
          <a-col :lg="24" :xs="24">
            <a-form-item name="name" label="Plan Name">
              <a-input
                v-model:value="form.name"
                placeholder="Enter plans name"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :xs="24">
            <a-form-item name="price" label="Price">
              <a-input v-if="package_id == ''"
                type="number"
                v-model:value="form.price"
                placeholder="Enter Price"
              />
              <a-input v-else
                type="number"
                v-model:value="form.price"
                placeholder="Enter Price"
                disabled='true'
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :xs="24">
            <a-form-item name="total_participant" label="Total Participant">
              <a-input
                type="number"
                v-model:value="form.total_participant"
                placeholder="Enter Total Participant"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :xs="24">
            <a-form-item name="plan_description" label="Plan Description">
              <a-input
                v-model:value="form.plan_description"
                placeholder="Enter Plan Description"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :xs="24">
            <label>Plan Features</label>
            <a-form-item v-for="(desc, index) in form.description"
              :key="desc.key"
              :name="['description', index, 'value']"
              :rules="{
                required: true,
                message: 'description can not be null',
                trigger: 'change',
              }"
            >
              <a-input
                placeholder="Enter Featuer"
                v-model:value="desc.value"
                style="width: 95%; margin-right: 8px"
              />
              <MinusCircleOutlined
                v-if="form.description.length > 1"
                class="dynamic-delete-button"
                :disabled="form.description.length === 1"
                @click="removeDescription(index)"
              />
            </a-form-item>
            <a-form-item>
              <a-button type="dashed" style="width: 95%" @click="addDescription">
                <PlusOutlined />
                Add field
              </a-button>              
            </a-form-item>
          </a-col>
          <a-col :lg="24" :xs="24">
            <sdButton @click="onSubmitPress" type="primary" class="pull-right">
              Save
            </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </sdCards>
  </Main>
</template>
<script>
import _ from "lodash";
import { API } from "@/services/api";
import { Main } from "../../styled";
import Notification from "@/services/Notification";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';

export default {
  name: "setupParticipant",
  components: { Main,MinusCircleOutlined,PlusOutlined },
  computed: {
    countries: function () {
      return this.$store.getters.countries;
    },
    formRef: function() {
      return this.$refs.formRef;
    }
  },
  data() {
    return {
      form: {
        name: "",
        price: "",
        total_participant: "",
        plan_description: "",
        description : [{value: "", key: new Date().valueOf()}]
      },
      package_id: '',
      formRules: {
        name: [{
          required: true, trigger: 'blur',
          message: 'Please enter plan name',
        }],
        price: [{
          required: true, trigger: 'blur',
          message: 'Please enter price',
        },
        {
          type: 'number', trigger: 'blur',transform: (val) => Number(val),
          message: 'Please enter price',
        }],
        total_participant: [{
          required: true, trigger: 'blur',
          message: 'Please enter total participant',
        }],
        plan_description: [{
          required: true, trigger: 'blur',
          message: 'Please enter plan description',
        }],
      },
    };
  },
  methods: {
    async onSubmitPress() {
      try {
        await this.formRef.validate();
        const fData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          if (key) {
            if (key == 'description') {
              fData.append(key, JSON.stringify(value.map(v => v.value)));
            } else {
              fData.append(key, value);
            }
          }
        }
        fData.append('id',this.package_id);
        const dataRes = await API.createPackage(fData);
        const { message, status } = dataRes.data;
        
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        // if(status) {
        //   this.$router.go(-2);
        // }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    async getPackageDetails() {
      const { package_id = "" } = this.$route.params;
      if(!package_id) {
        return null;
      }
      try {
        const res = await API.getPackageDetail({params: {
          id: package_id,
        }});
        this.package_id = package_id;

        const { data, status } = res?.data ?? {};
        if (status) {
          this.subscriber = data;
          const {name = "", price, total_participant,description, plan_description} = data

          this.form = {
              ...this.form,
              name,
              price: JSON.stringify(price),
              total_participant,
              plan_description,
              description: _.map(description, (d) => ({value: d, key: new Date().valueOf()}))
          };
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    addDescription() {
      const newDescription = [...this.form.description, { value: "", key: new Date().valueOf() }];
      this.form = {
        ...this.form,
        description: newDescription,
      };
    },
    removeDescription(index) {
      this.form.description.splice(index, 1);
    }
  },
  created() {
    this.getPackageDetails();
  }
};
</script>
